import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import Rollbar from 'rollbar';
import mitt from 'mitt'
import './assets/tailwind.css'

const emitter = mitt()
const app = createApp(App);

app.config.globalProperties.emitter = emitter

// Set the Rollbar instance in the Vue prototype
// before creating the first Vue instance.
// This ensures it is available in the same way for every
// instance in your app.
app.config.globalProperties.$rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
});

// If you have already set up a global error handler,
// just add `vm.$rollbar.error(err)` to the top of it.
// If not, this simple example will preserve the app’s existing
// behavior while also reporting uncaught errors to Rollbar.
// eslint-disable-next-line no-unused-vars
app.config.errorHandler = (err, vm, info) => {
  //vm.$rollbar.error(err);
  console.log(err);
  console.log(info);
  throw err; // rethrow
};

app.use(router);

app.mount('#app');
