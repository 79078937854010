import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomePage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutPage.vue')
  },
  {
    path: '/app.html',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardPage.vue')
  },
  {
    path: '/gift-event/:slug',
    name: 'GiftEventDashboardPage',
    component: () => import(/* webpackChunkName: "GiftEventDashboardPage" */ '../views/GiftEventDashboardPage.vue')
  },
  {
    path: '/gift-event/:slug/invite',
    name: 'GiftEventAddUserPage',
    component: () => import(/* webpackChunkName: "GiftEventAddUserPage" */ '../views/GiftEventAddUserPage.vue')
  },
  {
    path: '/new-gift-event',
    name: 'NewGiftEventPage',
    component: () => import(/* webpackChunkName: "NewGiftEventPage" */ '../views/NewGiftEventPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
